import React from "react";
import styled, { css } from "styled-components";

interface BaseSectionProps {
  children?: React.ReactNode;
  className?: string;
  withBackground?: boolean;
}

const BaseSection: React.FC<BaseSectionProps> = props => (
  <section className={props.className}>
    <div className="container py-5">{props.children}</div>
  </section>
);

export const Section = styled(BaseSection)`
  header h2 {
    font-size: 32px;
    color: #111;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
  }

  header h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  header h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #18d26e;
    bottom: 0;
    left: calc(50% - 20px);
  }

  header p {
    text-align: center;
    padding-bottom: 30px;
    color: #333;
  }

  ${props =>
    props.withBackground &&
    css`
      background: #f7f7f7;
    `}
`;

export const SectionWithBackground = styled(Section)`
  background: #f7f7f7;
`;
