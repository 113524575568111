import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Intro } from "../components/Intro";
import { Layout } from "../components/Layout";
import { Section } from "../components/section/Section";
import { SectionHeader } from "../components/section/SectionHeader";

const MapSection = styled.section`
  .map-responsive {
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
    height: 0;
  }

  .map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .map-responsive {
      padding-bottom: 50%;
    }
  }

  @media (min-width: 992px) {
    .map-responsive {
      padding-bottom: 25%;
    }
  }
`;

const WhereWeArePage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Dove Siamo - Milano sud ovest - via Lorenteggio - Piazza Frattini
        </title>
      </Helmet>
      <Intro
        title="Dove siamo"
        tagLine="La nostra esposizione si trova in Via Lorenteggio 72, vicino a Piazza Frattini"
      ></Intro>
      <MapSection>
        <div className="map-responsive">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11195.90255986086!2d9.136638!3d45.450147!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2299749b6f92caa!2sBrianzarredi%20s.n.c%20di%20Origgi%20Amedeo%2C%20Fabio%20e%20Gabriele!5e0!3m2!1sit!2sus!4v1577752647699!5m2!1sit!2sus"
            width="600"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
          ></iframe>
        </div>
      </MapSection>
      <Section>
        <SectionHeader>Orari di Apertura</SectionHeader>
        <div className="text-center">
          <dl>
            <dt>Lunedì</dt>
            <dd>15:00 - 19:30</dd>
          </dl>
          <dl>
            <dt>Da Martedì a Sabato</dt>
            <dd>10:00 - 13:00 / 15:00 - 19:30</dd>
          </dl>
        </div>
      </Section>
    </Layout>
  );
};

export default WhereWeArePage;
